<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Classified Listings</h3>
          <div>
            <router-link :to="{ name: 'create_listing' }" class="ps-btn btn-sm p-3" v-if="auth.isAuthenticated">
              <i class="icon-plus-circle"></i> Create Listing
            </router-link>
            &nbsp;&nbsp;
            <router-link :to="{ name: 'listings' }" class="ps-btn btn-sm p-3" v-if="auth.isAuthenticated"><i
                class="icon-list"></i> myListingInbox
            </router-link>
          </div>
        </div>

        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Listing</th>
                  <th>Price</th>
                  <th>Code</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="listing in listings" :key="listing.id">
                  <td>
                    <router-link :to="{ name: 'listing', params: { id: listing.id } }">
                      <img v-if="listing.images.length > 0" class="listing-image" :src="listing.images[0].url"
                        alt="Shop" />
                    </router-link>
                  </td>
                  <td>
                    <router-link class="text-secondary" :to="{ name: 'listing', params: { id: listing.id } }">
                      {{ listing.title }}
                    </router-link>
                  </td>
                  <td class="text-right">{{ listing.price }}</td>
                  <td>{{ listing.id }}</td>
                  <td>
                    <router-link :to="{ name: 'listing', params: { id: listing.id } }">
                      View </router-link>/
                    <router-link :to="{ name: 'edit_listing', params: { id: listing.id } }">
                      Edit
                    </router-link>/
                    <button @click="handleDelete(listing.id)" class="btn btn-danger">Delete</button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">
                    No listings available...
                  </td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">
                    Loading listings...
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Listings",
  data() {
    return {
      listings: [],
      meta: {},
      listingCategories: [],
      currentCategory: "",
      isLoading: false,

      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  watch: {
    $route(to, from) {
      this.currentCategory = this.$route.params.category_id
        ? this.$route.params.category_id
        : "1";
      this.getListings();
    },
  },
  mounted() {
    this.currentCategory = this.$route.params.category_id
      ? this.$route.params.category_id
      : "1";
    this.getListings();
    this.setUpJS();
  },
  methods: {
    getListings() {
      this.$loading(false);
      this.url = "listings/user";
      this.isLoading = true;
      axios.get(this.url).then((response) => {
        this.listings = response.data.data;
        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);

        this.$loading(false);
        this.isLoading = false;
      });
    },

    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        this.getListings();
      }
      this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        this.getListings();
      }
      this.scrollTop();
    },

    handleDelete() {

    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },

    handleDelete(id) {
      let index = this.listings.indexOf(id);
      let uri = `listings/user/${id}`;
      axios.delete(uri).then((response) => {
        this.$toasted.show(response.data.message);
        this.listings.splice(index, 1);
      });
    },

    setUpJS() {
      $("#toggle_no_wrap").on("click", function () {
        $("#listing_cats").toggleClass("listings-categories");
        $(this).toggleClass("icon-chevron-down-circle");
        $(this).toggleClass("icon-chevron-up-circle");
      });
    },
  },
};
</script>
<style scoped>
.listing-image {
  max-width: 70px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
}
</style>
